import { getImageProps } from 'next/image';
import { useMedia } from 'react-use';
import { UmbracoTypes } from '~/lib/data-contract';
import { Button } from '~/shared/components/Button';
import { Spacer } from '~/shared/components/Spacer';
import { Text } from '~/shared/components/Text';
import { Video } from '~/shared/components/Video';
import { breaks, queries, theme } from '~/theme';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { ModuleContainer } from '../../shared/ModuleContainer';

import {
    BrandImageWrapper,
    Link,
    StyledContent,
    StyledHeadline,
    StyledHeroItem,
    StyledImageWrapper,
    StyledM11HeroSplit,
    StyledVideoWrapper,
} from './styled';
import getFocalPoint from '../../utils/getFocalPoint';
import { useModule } from '../../hooks/useModule';
import { weakKey } from '~/shared/utils/jsx';
import { ImageOverlay, UmbracoImage } from '~/shared/components/Image';
import { umbracoLoader } from '~/shared/components/Image/loaders/umbracoLoader';

type M11HeroSplit = UmbracoTypes.IM11HeroSplitModule;
type M11HeroSplitItem = UmbracoTypes.IM11HeroSplitItem;

export const M11HeroSplit = (props: M11HeroSplit) => {
    return (
        <ModuleContainer hasGutter>
            <StyledM11HeroSplit>
                {props.hero?.map((item) =>
                    item.callToAction ? (
                        <Link
                            href={item.callToAction.url}
                            target={item.callToAction.target}
                            key={weakKey(item)}
                        >
                            <Hero11SplitItem {...item} />
                        </Link>
                    ) : (
                        <Hero11SplitItem {...item} key={weakKey(item)} />
                    ),
                )}
            </StyledM11HeroSplit>
        </ModuleContainer>
    );
};

const Hero11SplitItem = (props: M11HeroSplitItem) => {
    const {
        headline,
        subHeadline,
        backgroundColor: initialBackgroundColor,
        backgroundColorHEX: initialBackgroundColorHex,
        callToActionTypeFill,
        callToAction,
        brandLogo,
        image,
        imageMobile,
        mobileContentPosition,
        video,
    } = props;
    const { index } = useModule();

    const { textColor, backgroundColor, buttonColor, buttonVariant } = useModuleTheme({
        backgroundColor: initialBackgroundColorHex || initialBackgroundColor,
        buttonVariant: callToActionTypeFill ? 'fill' : 'stroke',
    });

    const mediaType = video ? 'video' : 'image';
    const isTabletUp = useMedia(queries.md, false);

    const {
        props: { srcSet: desktop, ...desktopProps },
    } = getImageProps({
        loader: umbracoLoader,
        alt: image?.alt ?? 'Hero Split Image Desktop',
        src: image?.src ?? '',
        fill: true,
        sizes: `${breaks.pageMax}px`,
        loading: index < 2 ? 'eager' : 'lazy',
        style: {
            objectFit: 'cover',
            objectPosition: getFocalPoint(image),
        },
    });

    const {
        props: { srcSet: mobile, ...mobileProps },
    } = getImageProps({
        loader: umbracoLoader,
        fill: true,
        alt: imageMobile?.alt ?? 'Hero Split Image Mobile',
        src: imageMobile?.src ?? '',
        loading: index < 2 ? 'eager' : 'lazy',
        sizes: '80vw',
        style: {
            objectFit: 'cover',
            objectPosition: getFocalPoint(imageMobile),
        },
    });

    const isEmptyContent = headline || subHeadline || callToAction;

    return (
        <StyledHeroItem
            backgroundColor={backgroundColor}
            mobileContentPosition={mobileContentPosition}
        >
            {brandLogo?.src ? (
                <BrandImageWrapper>
                    <UmbracoImage
                        src={brandLogo.src}
                        style={{ objectFit: 'contain' }}
                        sizes={theme.space[12]}
                        fill
                        alt={brandLogo.alt ?? ''}
                    />
                </BrandImageWrapper>
            ) : null}

            {isEmptyContent && (
                <StyledContent
                    textColor={textColor}
                    isBrandLogo={brandLogo?.src === undefined}
                    mobileContentPosition={mobileContentPosition}
                >
                    {headline ? (
                        <StyledHeadline variant={'display1'}>{headline}</StyledHeadline>
                    ) : null}
                    {subHeadline ? (
                        <>
                            <Spacer size="4" customFrom={{ '451px': '5' }} />
                            <Text variant="display6" as="p" style={{ color: textColor }}>
                                {subHeadline}
                            </Text>
                        </>
                    ) : null}
                    {callToAction ? (
                        <>
                            <Spacer size="4" customFrom={{ '451px': '5', '1000px': '6' }} />
                            <Button variant={buttonVariant} color={buttonColor}>
                                {callToAction.text}
                            </Button>
                        </>
                    ) : null}
                </StyledContent>
            )}

            <StyledImageWrapper mobileContentPosition={mobileContentPosition}>
                {imageMobile?.src || image?.src ? (
                    <picture>
                        <source media={imageMobile?.src ? `${queries.md}` : ''} srcSet={desktop} />
                        {imageMobile?.src && <source srcSet={mobile} />}
                        {/* eslint-disable-next-line */}
                        <img {...(isTabletUp ? desktopProps : mobileProps)} />
                    </picture>
                ) : (
                    <ImageOverlay />
                )}
            </StyledImageWrapper>
            {mediaType === 'video' ? (
                <StyledVideoWrapper>
                    <Video
                        muted={true}
                        controls={false}
                        src={video?.src ?? ''}
                        loop={video?.loop ?? false}
                        playing={video?.autoPlay ?? false}
                        posterSrc={image?.src}
                        cover={true}
                    />
                </StyledVideoWrapper>
            ) : null}
        </StyledHeroItem>
    );
};
