import styled from '@emotion/styled';
import { breakpoints } from '$theme';
import { Headline } from '../../shared/Headline';
import { ifProp } from 'styled-tools';
import NextLink from 'next/link';

type StyledM11HeroSplitProps = {
    backgroundColor?: string;
    mobileContentPosition?: 'above' | 'beneath';
};

type StyledContentProps = {
    textColor?: string;
    mobileContentPosition?: 'above' | 'beneath';
    isBrandLogo?: boolean;
};

export const StyledM11HeroSplit = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.space[2],
    [breakpoints.md]: {
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        gap: theme.space[5],
        width: '100%',
        minHeight: '500px',
        maxHeight: '630px',
    },
}));

export const StyledImageWrapper = styled.div<StyledContentProps>(({ mobileContentPosition }) => ({
    position: 'relative',
    gridRow: mobileContentPosition === 'above' ? '3/4' : '1/2',
    gridColumn: '1/4',
    maxHeight: '480px',
    aspectRatio: '1/1.5',
    width: '100%',

    [breakpoints.md]: {
        gridColumn: '1/5',
        aspectRatio: '1/1',
        minHeight: '500px',
        maxHeight: '630px',
        gridRow: '1/2',
        height: '100%',
    },
}));

export const StyledContent = styled.div<StyledContentProps>(
    ({ textColor, theme, mobileContentPosition, isBrandLogo }) => ({
        color: textColor,
        alignSelf: 'center',
        padding: `${
            (mobileContentPosition === 'beneath' && !isBrandLogo) || isBrandLogo ? '30px' : '140px'
        } ${theme.space[3]} ${theme.space[4]} ${theme.space[3]}`,

        zIndex: 5,
        gridColumn: '1 / 4',
        gridRow: mobileContentPosition === 'above' ? '1 / 2' : '2/3',

        '& p': {
            maxWidth: '350px',
        },

        [breakpoints.md]: {
            gridRow: '1 / 2',
        },
    }),
);

export const StyledHeroItem = styled.div<StyledM11HeroSplitProps>(
    (props) => ({
        display: 'grid',
        position: 'relative',
        height: '100%',

        [breakpoints.md]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
            height: '100%',
        },
    }),

    ifProp('backgroundColor', ({ backgroundColor, mobileContentPosition, theme }) => ({
        backgroundColor: backgroundColor,
        gridTemplateRows: `minmax(${theme.space[12]},auto) minmax(${theme.space[12]},auto)`,

        [`${StyledImageWrapper}`]: {
            gridColumn: '1/4',
            gridRow: mobileContentPosition === 'above' ? '2/3' : '1/2',
            aspectRatio: '1/1.5',
            maxHeight: '480px',
            width: '100%',
            [breakpoints.md]: {
                gridColumn: '3/5',
                height: '100%',
                aspectRatio: '1/2',
                maxHeight: '630px',
                gridRow: '1/3',
            },
        },
        [`${StyledContent}`]: {
            gridRow: mobileContentPosition === 'above' ? '1/2' : '2/3',
            [breakpoints.md]: {
                gridColumn: '1/3',
                gridRow: '1/3',
            },
        },
        [`${StyledVideoWrapper}`]: {
            gridRow: mobileContentPosition === 'above' ? '2/3' : '1/2',
            [breakpoints.md]: {
                gridColumn: '1/5',
                gridRow: '1/3',
            },
        },
    })),
);

export const StyledHeadline = styled(Headline)({
    color: 'inherit',
});

export const StyledVideoWrapper = styled.div<StyledContentProps>({
    width: '100%',
    height: '100%',
    gridColumn: '1/4',
    gridRow: '1/4',
    zIndex: 4,
    [breakpoints.md]: {
        gridColumn: '1/5',
        gridRow: '1/3',
    },
});

export const BrandImageWrapper = styled.div(({ theme }) => ({
    position: 'absolute',
    top: theme.space[3],
    left: theme.space[3],
    zIndex: 5,
    width: theme.space[12],
    height: 75,
}));

export const Link = styled(NextLink)({
    textDecoration: 'none',
});
